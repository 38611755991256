<template>
    <div class="container-xl" v-if="!$store.getters.isForbidden">
      <div class="card">
        <div class="card-header justify-content-between">
          <div class="card-title">Pre-Order List</div>
          <el-button type="primary" @click="handlerBtnCreateNewPO" size="small"><font-awesome-icon icon="plus"/> Create New Pre-Order</el-button>
        </div>
        <div class="mt-2 pl-2 pr-2 mb-2">
          <div class="flex">
            <div class="w-1/2">
              <el-form ref="form" label-width="120px">
                <el-form-item label="Order Date">
                  <el-date-picker
                    v-model="filter.date_range"
                    type="daterange"
                    size="small"
                    :picker-options="datePickerOptions"
                    class="w-full"
                    range-separator="To"
                    start-placeholder="Start date"
                    end-placeholder="End date">
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="Merchant">
                    <el-select size="small" v-model="filter.merchant_id" placeholder="Merchant" class="w-full" filterable remote :remote-method="searchMerchant">
                    <el-option
                      v-for="item in merchantList"
                      :key="item.merchant_id"
                      :label="item.merchant_name"
                      :value="item.merchant_id">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="Status">
                  <el-select size="small" v-model="filter.status" placeholder="Merchant Status" filterable style="width: 150px">
                    <el-option
                      v-for="item in statusOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-form>
            </div>
            <!-- <el-input clearable @clear="searchHandler" style="width: 200px" size="small" type="text" v-model="search_keyword" placeholder="Input search keyword" class="mr-2"/>
             -->
          </div>
          <el-button type="info" @click="searchHandler" size="small"><font-awesome-icon icon="search"/> Search</el-button>
        </div>
        <div class="table-responsive">
          <table class="table card-table text-nowrap table-vcenter">
            <thead>
              <tr>
                <th width="30%">Order Date</th>
                <th width="30%">PO Code</th>
                <th width="20%">Merchant Name</th>
                <th width="10%">Voucher Name</th>
                <th width="10%">Qty</th>
                <th width="10%">Price</th>
                <th width="10%">Margin</th>
                <th width="10%">Status</th>
              </tr>
            </thead>
            <tbody v-loading="isLoading">
              <tr v-for="(item, i) in list" :key="i">
                <td>{{ item.created_at_str }}</td>
                <td>{{ item.po_code }}</td>
                <td>{{ item.merchant_name }}</td>
                <td>{{ item.voucher_name }}</td>
                <td>{{ item.voucher_qty}}</td>
                <td>Rp.{{ (item.voucher_po_price || 0).toLocaleString()}}</td>
                <td>{{ item.margin_fee }}%</td>
                <td>
                  <template v-if="item.reject_reason">
                    <el-popover
                      placement="left"
                      title="Reject Reason"
                      width="200"
                      trigger="hover"
                      :content="item.reject_reason">
                      <span slot="reference" class="tag" :class="item.status_color">{{ item.status_str }}</span>
                    </el-popover>
                  </template>
                  <span v-else class="tag" :class="item.status_color">{{ item.status_str }}</span>
                </td>
              </tr>
            </tbody>
          </table>
          <el-empty description="No data found" v-if="total_rows == 0" :image="emptyStateImage"></el-empty>
        </div>
        <div class="card-footer d-flex justify-content-between">
          <small>Showing {{ showingFrom }} to {{ showingUntil }} of {{ total_rows }} entries</small>
          <div class="inline-table">
            <b-pagination
              class="table-cell"
              @change="pageChangeHandler"
              :per-page="per_page"
              :limit="5"
              :total-rows="total_rows"
              v-model="page"
            />
          </div>
        </div>
      </div>
      <!-- <b-modal size="lg" v-model="modal.create_po" title="Create New Pre-Order" hide-footer @hide="modal.create_po = false; resetFormPO()"> -->
      <b-modal size="lg" v-model="modal.create_po" title="Create New Pre-Order" hide-footer @hide="modal.create_po = false; resetFormPO()">
        <b-form @submit.prevent="onSubmitCreatePO" @reset="resetFormPO()">
          <b-form-group label="Merchant Name">
            <el-select @change="handlerMerchantChange" size="small" v-model="v$.form_po.merchant_id.$model" placeholder="Choose merchant" class="w-full" filterable remote :remote-method="searchMerchantPO">
              <el-option
                v-for="item in merchant_list_po"
                :key="item.merchant_id"
                :label="item.merchant_name"
                :value="item.merchant_id">
              </el-option>
            </el-select>
            <b-form-invalid-feedback :state="!v$.form_po.merchant_id.$invalid" v-if="v$.form_po.merchant_id.$errors[0]">
              <span> {{ v$.form_po.merchant_id.$errors[0].$message }}</span>
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label="Voucher Name">
            <el-select size="small" v-model="v$.form_po.voucher_id.$model" placeholder="Choose voucher" class="w-full">
              <el-option
                v-for="item in voucher_list_po"
                :key="item.id"
                :label="item.voucher_name"
                :value="item.id">
              </el-option>
            </el-select>
            <b-form-invalid-feedback :state="!v$.form_po.voucher_id.$invalid" v-if="v$.form_po.voucher_id.$errors[0]">
              <span> {{ v$.form_po.voucher_id.$errors[0].$message }}</span>
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label="Quantity">
            <el-input v-model="v$.form_po.qty.$model" clear="w-full"></el-input>
            <b-form-invalid-feedback :state="!v$.form_po.qty.$invalid" v-if="v$.form_po.qty.$errors[0]">
              <span> {{ v$.form_po.qty.$errors[0].$message }}</span>
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label="Import PO Voucher to">
            <el-select size="small" v-model="v$.form_po.target_voucher_id.$model" placeholder="Choose target voucher" class="w-full" :remote-method="handlerSearchVoucher" remote filterable>
              <el-option
                v-for="item in voucher_list"
                :key="item.voucher_id"
                :label="item.voucher_name"
                :value="item.voucher_id">
              </el-option>
            </el-select>
            <b-form-invalid-feedback :state="!v$.form_po.target_voucher_id.$invalid" v-if="v$.form_po.target_voucher_id.$errors[0]">
              <span> {{ v$.form_po.target_voucher_id.$errors[0].$message }}</span>
            </b-form-invalid-feedback>
          </b-form-group>
          <div class="d-flex flex-row float-right">
            <el-button @click="handlerSubmitPO" :loading="loading.create_po" class="mr-2 ml-2" size="small" type="primary">Submit</el-button>
            <el-button @click="modal.create_po = false; resetFormPO()" size="small" class="mr-2">Cancel</el-button>
            <!-- <b-button class="mr-2" size="sm" @click="closeModalGiveVoucher" type="reset" variant="secondary">Cancel</b-button> -->
          </div>
        </b-form>
      </b-modal>
    </div>
</template>
<script lang="js">
import moment from 'moment';
import useVuelidate from '@vuelidate/core';
// import { quillEditor } from 'vue-quill-editor';
import { cloneDeep } from 'lodash';
// require styles
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import {
  required, numeric, minValue,
} from '@vuelidate/validators';

import {
  GET_MERCHANTS, PREORDER_LIST_MERCHANT, PREORDER_VOUCHER_LIST_MERCHANT, PREORDER_SUBMIT,
} from '@/store/modules/merchants';
import { GET_CATEGORYS } from '@/store/modules/categorys';
import { GET_VOUCHERS } from '@/store/modules/vouchers';
import emptyStateImage from '@/assets/images/empty-state.png';
import base64File from '@/library/base64File';
import popupErrorMessages from '@/library/popup-error-messages';
import imageCompress from '@/library/vue-image-compression';

export default {
  name: 'MerchantAppsPo',
  metaInfo: {
    title: 'Merchant Apps - Pre-Order',
  },
  components: {
    // quillEditor,
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      emptyStateImage,
      merchantList: [],
      modal: {
        create_po: false,
      },
      loading: {
        modal_merchant: false,
        create_po: false,
      },
      editorOption: {
        placeholder: 'Input merchant description',
      },
      filter: {
        status: '',
        date_range: [
          moment().subtract(1, 'month').format(),
          moment().format(),
        ],
        merchant_id: '',
      },
      form_po: {
        voucher_id: '',
        merchant_id: '',
        qty: 1,
        target_voucher_id: null,
      },
      merchant_list_po: [],
      voucher_list_po: [],
      voucher_list: [],
      merchant_id: '',
      filter_merchant_loading: false,
      per_page: 20,
      total_rows: 0,
      page: 1,
      list: [],
      isLoading: true,
      loader: null,
      search_keyword: '',
      search_by: '',
      search_merchant_keyword: '',
      selected_file: {
        merchant_banner: null,
        merchant_banner_base64: null,
        merchant_photo_url: null,
        merchant_photo_url_base64: null,
      },
      merchant_status: '',
      categoryList: [],
      total_rows_category: 0,
      statusOptions: [
        {
          value: '',
          label: 'All',
        },
        {
          value: 'request',
          label: 'Requested',
        },
        {
          value: 'approved_merchant',
          label: 'Approved',
        },
        {
          value: 'rejected_merchant',
          label: 'Rejected',
        },
      ],
      form: {
        merchant_name: '',
        merchant_address: '',
        merchant_pic: '',
        merchant_telp: '',
        merchant_description: '',
        merchant_photo_url: '',
        merchant_banner: '',
        merchant_logo: '',
        merchant_category_id: '',
        is_highlight: false,
        is_homepage_highlight: false,
        is_registered_merchant_app: true,
        merchant_is_top_list: 0,
        merchant_pic_telp: '',
        merchant_pic_email: '',
        merchant_pic_password: '',
        merchant_margin_fee_amount: 0,
      },
      modalTitle: 'Create new Merchant',
      showModalMerchant: false,
      datePickerOptions: {
        disabledDate: (date) => moment(date).isAfter(moment(), 'days'),
      },
    };
  },
  validations() {
    return {
      form_po: {
        merchant_id: { required },
        voucher_id: { required },
        qty: { required, numeric, minValue: minValue(1) },
        target_voucher_id: { required },
      },
    };
  },
  computed: {
    showingFrom() {
      return this.total_rows ? ((this.page - 1) * this.per_page) + 1 : 0;
    },
    showingUntil() {
      if (this.total_rows) {
        if (this.list.length < this.per_page) {
          return this.total_rows;
        }
        return this.page * this.per_page;
      }
      return 0;
    },
  },
  async mounted() {
    const loader = this.$loading.show();
    this.getMerchantList();
    await this.getPreOrderList();
    loader.hide();
  },
  methods: {
    resetFormPO() {
      this.form_po = {
        voucher_id: '',
        merchant_id: '',
        qty: 1,
        target_voucher_id: '',
      };
    },
    pageChangeHandler(page) {
      this.page = page;
      this.getList();
    },
    searchHandler() {
      this.page = 1;
      this.search_by = 'merchant_name';
      this.getPreOrderList();
    },
    async getPreOrderList() {
      this.isLoading = true;
      await this.$store.dispatch(PREORDER_LIST_MERCHANT, {
        id: this.$route.params.id || (this.filter.merchant_id || 'all'),
        query: {
          page: this.page,
          per_page: this.per_page,
          search_by: this.search_by,
          search_keyword: this.search_keyword,
          status_filter: this.filter.status,
          date_range: this.filter.date_range,
          merchant_id: this.filter.merchant_id,
        },
      }).then(({ data }) => {
        ({ count: this.total_rows, rows: this.list } = data);
        if (!data.count) {
          this.total_rows = 0;
          this.list = [];
        }
        this.list = this.list.map((v) => {
          v.merchant_name = v.merchant.merchant_name;
          v.voucher_name = v.voucher.voucher_name;
          v.created_at_str = moment(v.created_at).format('DD MMM YYYY, HH:mm');
          v.status_str = 'Requested';
          v.status_color = 'tag-warning';
          if (v.status === 'approved_merchant') {
            v.status_str = 'Approved';
            v.status_color = 'tag-success';
          }
          if (v.status === 'rejected_merchant') {
            v.status_str = 'Rejected';
            v.status_color = 'tag-danger';
          }
          return v;
        });
      }).catch(() => {});
      this.isLoading = false;
    },
    async getList() {
      this.isLoading = true;
      await this.$store.dispatch(GET_MERCHANTS, {
        page: this.page,
        per_page: this.per_page,
        search_by: this.search_by,
        search_keyword: this.search_keyword,
        status_filter: this.merchant_status,
        is_registered_merchant_app: true,
      }).catch(() => {});
      const { rows, count } = this.$store.getters.merchants;
      this.total_rows = count || 0;
      this.list = rows.map((v) => {
        v.merchant_app_status_str = v.is_registered_merchant_app ? 'Registered' : 'Not Registered';
        v.merchant_app_status_color = v.is_registered_merchant_app ? 'tag-primary' : 'tag-default';
        return v;
      });
      this.isLoading = false;
    },
    clickEditMerchant(item) {
      this.modalType = 'edit';
      this.modalTitle = 'Edit Merchant';
      this.showModalMerchant = true;
      this.getCategoryList();
      this.form = cloneDeep(item);
      this.form.current_merchant_banner = item.merchant_banner;
      this.form.current_merchant_photo_url = item.merchant_photo_url;
      this.v$.form.$touch();
    },
    clikCreateMerchant() {
      this.modalType = 'add';
      this.modalTitle = 'Create New Merchant';
      this.showModalMerchant = true;
      this.getCategoryList();
      this.v$.form.$touch();
    },
    closeModalMerchant() {
      this.showModalMerchant = false;
      this.form = {
        merchant_name: '',
        merchant_address: '',
        merchant_pic: '',
        merchant_telp: '',
        merchant_description: '',
        merchant_photo_url: '',
        merchant_banner: '',
        merchant_logo: '',
        merchant_category_id: '',
        is_highlight: false,
        is_homepage_highlight: false,
        merchant_is_top_list: 0,
      };
    },
    onSubmitMerchant() {
      this.v$.form.$touch();
      if (this.v$.form.$error) {
        return;
      }
      let msg = 'You will create new merchant. Continue?';
      if (this.modalType === 'edit') {
        msg = 'You will update this merchant data. Continue?';
      }
      this.$confirm(msg, 'Confirmation', {
        type: 'info',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            if (this.modalType === 'add') {
              this.createMerchant();
            } else if (this.modalType === 'edit') {
              this.updateMerchant();
            }
          }
          done();
        },
      }).catch(() => {});
    },
    async onChangeFile(event, target) {
      if (this.selected_file[target]) {
        const compressed = await imageCompress(this.selected_file[target]);
        this.selected_file[`${target}_base64`] = await base64File(compressed);
        try {
          this.$refs[`current_${target}`].src = this.selected_file[`${target}_base64`];
          this.form[`current_${target}`] = this.selected_file[`${target}_base64`];
          this.form[target] = this.form[`current_${target}`];
        } catch (error) {
          // console.log(error);
        }
      }
    },
    async getCategoryList() {
      await this.$store.dispatch(GET_CATEGORYS, {
        search_by: 'category_type',
        search_keyword: '1',
      }).catch((err) => console.log(err));
      const { rows, count } = this.$store.getters.categorys;
      this.total_rows_category = count || 0;
      this.categoryList = rows;
    },
    async searchMerchantPO(query) {
      // this.getMerchantList();
      await this.$store.dispatch(GET_MERCHANTS, {
        page: this.page,
        per_page: this.per_page,
        search_by: 'merchant_name',
        search_keyword: query,
        is_registered_merchant_app: true,
      }).then(() => {
        const { rows } = this.$store.getters.merchants;
        this.merchant_list_po = rows;
      }).catch(() => {});
    },
    searchMerchant(query) {
      this.search_merchant_keyword = query;
      this.getMerchantList();
    },
    async getMerchantList() {
      this.filter_merchant_loading = true;
      await this.$store.dispatch(GET_MERCHANTS, {
        page: this.page,
        per_page: this.per_page,
        search_by: 'merchant_name',
        search_keyword: this.search_merchant_keyword,
        is_registered_merchant_app: true,
      }).catch(() => {});
      this.merchantList = [{
        merchant_name: 'All',
        merchant_id: '',
      }];
      const { rows } = this.$store.getters.merchants;
      this.merchantList.push(...rows);
      this.filter_merchant_loading = false;
    },
    async getVoucherPOList() {
      await this.$store.dispatch(PREORDER_VOUCHER_LIST_MERCHANT, {
        id: this.form_po.merchant_id,
      }).then(({ data }) => {
        this.voucher_list_po = data.rows;
      }).catch(() => {});
    },
    handlerBtnCreateNewPO() {
      this.modal.create_po = true;
      this.resetFormPO();
      this.searchMerchantPO();
      this.getVoucherList();
    },
    onSubmitCreatePO() {},
    handlerMerchantChange() {
      this.form.voucher_id = '';
      this.getVoucherPOList();
    },
    handlerSearchVoucher(query) {
      this.getVoucherList(query);
    },
    async getVoucherList(query = '') {
      await this.$store.dispatch(GET_VOUCHERS, {
        search_by: 'voucher_name',
        search_keyword: query,
        per_page: this.per_page,
      }).catch(() => {});
      const { rows } = this.$store.getters.vouchers;
      this.voucher_list = rows;
    },
    async handlerSubmitPO() {
      this.v$.form_po.$touch();
      if (this.v$.form_po.$error) return;

      this.loading.create_po = true;
      await this.$store.dispatch(PREORDER_SUBMIT, {
        id: this.form_po.merchant_id,
        data: this.form_po,
      }).then(() => {
        this.$message({
          title: 'Success',
          type: 'success',
          message: 'Pre-Order successfully sent',
        });
        this.modal.create_po = false;
        this.resetFormPO();
        this.getPreOrderList();
      }).catch((err) => {
        if (err.response?.data?.error?.title === 'duplicated') {
          this.$message({
            title: 'Oops',
            type: 'warning',
            message: 'Duplicate pre-order for this voucher from this merchant',
            duration: 5000,
          });
          return;
        }
        popupErrorMessages(err.response?.data).catch(() => {});
      });
      this.loading.create_po = false;
    },
  },
};
</script>

<style scoped>
/* .custom-popper-class {
  max-height: 300px !important; 
} */
</style>
